<template>
  <div class="h-screen flex flex-col border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
    <div class="flex-none h-16 p-3 flex flex-row justify-between items-center d-theme-dark-bg border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
      <div class="flex flex-row items-center space-x-3">
        <feather-icon @click="close" icon="XIcon" class="cursor-pointer" svgClasses="h-6 w-6"/>
        <p class="font-semibold">Report Access Control</p>
      </div>
    </div>
    <div class="flex-1 w-full bg-red overflow-hidden">
      <div class="flex h-full w-full bg-blue">
        <!----------- USERS ------------->
        <div class="flex-none w-3/12 d-theme-dark-bg">
            <UsersSection @selected="selectedUser = $event"/>
        </div>

        <!----------- ROLES ------------->
        <div class="flex-none w-3/12 d-theme-dark-bg">
            <RolesSection @selected="selectedRole = $event" :selectedUser="selectedUser" :updatedRole="updatedRole"/>
        </div>

        <!----------- PERMISSIONS ------------->
        <div class="flex-none w-6/12 h-full d-theme-dark-bg">
          <ReportsSection @saved="updatedRole = $event" :selectedRole="selectedRole"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersSection from '@/views/pages/reports/acl/sections/UsersSection'
import RolesSection from '@/views/pages/reports/acl/sections/RolesSection'
import ReportsSection from '@/views/pages/reports/acl/sections/ReportsSection'
import moduleAccessControl from '@/store/modules/general/access_control/access-control.store'

export default {
  name: 'ReportAccessControl',
  components: {
    UsersSection,
    RolesSection,
    ReportsSection
  },
  data () {
    return {
      selectedUser: null,
      selectedRole: null,
      updatedRole: null
    }
  },
  methods: {
    close () {
      this.$router.push({ name: 'report' })
    }
  },
  beforeCreate () {
    this.$store.registerModule(['general', 'accessControl'], moduleAccessControl)
  },
  beforeDestroy () {
    this.$store.commit('general/accessControl/RESET_STATE')
    this.$store.unregisterModule(['general', 'accessControl'])
  }
}
</script>
