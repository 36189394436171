<template>
  <div class="h-full w-full">
    <div class="h-full flex flex-col border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex-none">
        <div class="flex border items-center">
          <vs-input v-model="search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari User" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
        </div>
      </div>
      <div class="flex-none">
        <div class="flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
          <div class="flex py-2">
            <feather-icon @click="onAddClicked" icon="PlusIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
            <feather-icon @click="onEditClicked" icon="EditIcon" class="cursor-pointer px-3" svg-classes="h-4 w-4" :class="selected ? 'cursor-pointer hover:text-primary' : 'cursor-not-allowed'"/>
            <feather-icon @click="onDeleteClicked" icon="TrashIcon" class="cursor-pointer px-3" svg-classes="h-4 w-4" :class="selected ? 'cursor-pointer hover:text-primary' : 'cursor-not-allowed'"/>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <div class="flex-none">
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="loading || listUsers.length === 0">
          <p class="text-xs text-center">{{ loading ? 'Loading...' : 'Tidak ada data.' }}</p>
        </div>
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="!loading && listUsers.length > 0">
          <p class="text-xs">Total {{ listUsers.length }} users</p>
        </div>
      </div>
      <div class="flex-1 overflow-y-scroll">
        <div v-for="(item, index) in listUsers" :key="index" @click="onItemSelected(item)" class="cursor-pointer select-none border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
          <div class="sm:px-4 px-2 py-2" :class="{ 'bg-primary-transparent-25': selected === item }">
            <div class="flex">
              <feather-icon v-if="item.type === 'STAF'" icon="UserIcon" class="pr-3" svg-classes="h-5 w-5"/>
              <vs-icon v-if="item.type === 'REKANAN'" size="small" class="p-0 mr-3 h-5 w-5" icon-pack="material-icons" icon="business"/>
              <div class="flex w-full justify-between items-start">
                <div class="">
                  <h6 class="font-medium text-sm">{{ item.name }}</h6>
                  <span class="text-sm bg-ema">{{ item.username }}</span>
                  <p class="opacity-75" style="font-size: 9px">{{ item.type }}</p>
                </div>
                <span class="text-xs bg-ema text-primary font-medium">{{ item.roleCode }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <UserAdd :isActive.sync="modalAdd.active" @success="getUsers"/>
    <UserEdit :isActive.sync="modalEdit.active" :item="modalEdit.item" @success="getUsers"/>
  </div>
</template>

<script>
import AclRepository from '@/repositories/general/acl-repository'
import _ from 'lodash'

export default {
  name: 'UsersSection',
  components: {
    UserAdd: () => import('@/views/pages/general/access-control/users/UserAdd'),
    UserEdit: () => import('@/views/pages/general/access-control/users/UserEdit')
  },
  mounted () {
    this.getUsers()
  },
  computed: {
    listUsers () {
      const items = this.users
      // if search
      const search = this.search ? this.search.toLowerCase() : null
      if (search != null) {
        return _.filter(items, item => {
          return item.name.toLowerCase().includes(search) || item.username.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  data () {
    return {
      search: null,
      loading: false,
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      users: [],
      selected: null
    }
  },
  methods: {
    getUsers () {
      this.loading = true
      AclRepository.getUsers()
        .then(response => {
          this.users = response.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onAddClicked () {
      this.modalAdd.active = true
    },

    onEditClicked () {
      if (this.selected) {
        this.modalEdit.active = true
        this.modalEdit.item = this.selected
      }
    },

    onDeleteClicked () {
      if (this.selected) {
        this.confirmDelete(this.selected.id)
      }
    },

    onItemSelected (item) {
      this.selected = item
      this.emitOnItemSelected()
    },

    refresh () {
      this.selected = null
      this.getUsers()
    },

    delete (id) {
      AclRepository.deleteUser(id)
        .then(response => {
          this.getUsers()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Data master tidak akan ikut terhapus. Lanjutkan hapus user ' + this.selected.name + '?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    emitOnItemSelected () {
      this.$emit('selected', this.selected)
    }
  }
}
</script>
