<template>
  <div class="h-full w-full">
    <div class="h-full flex flex-col border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex-none">
        <div class="flex border items-center">
          <vs-input v-model="search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari Nama Report" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
        </div>
      </div>
      <div class="flex-none">
        <div class="flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
          <div class="flex">
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
            <feather-icon @click="toggleExpandAll(null)" :icon="isExpandAll ? 'ChevronsUpIcon' : 'ChevronsDownIcon'" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
          <div class="flex">
            <vs-button :disabled="!isAnyUnsavedChanges || this.isSaving" @click="save()" class="px-2" color="success" size="small" type="filled" icon-pack="feather" icon="icon-save">
              <span v-if="isSaving" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isSaving">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
      <div class="flex-none">
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="isLoading || listReportsGrouped.length === 0">
          <p class="text-xs text-center">{{ isLoading ? 'Loading...' : 'Tidak ada data.' }}</p>
        </div>
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="selectedRole && !isLoading && listReportsGrouped.length > 0">
          <div class="flex justify-between">
            <p class="text-xs">Total {{ totalReports }} reports in {{ reportsGrouped.length }} groups</p>
            <div class="flex items-center">
              <p class="text-xs text-success font-bold">Role {{ selectedRole.kode || '' }}</p>
              <feather-icon icon="ArrowRightIcon" class="mx-2" svg-classes="h-4 w-4"/>
              <p class="text-xs text-success font-semibold">{{ checked.length }} Reports</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 flex items-center justify-center w-full h-full" v-if="!selectedRole">
        <p class="text-sm text-center">Silahkan pilih <span class="font-semibold">Role</span> terlebih dahulu.</p>
      </div>
      <div class="flex-1 overflow-y-scroll" v-else>
        <div v-for="(group, index) in listReportsGrouped" :key="index" class="cursor-pointer mail-item-border-bottom select-none border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light" :class="{'opacity-50': group.is_all_items_hidden}">
          <div class="sm:px-4 px-2 pt-2 pb-1" @click="group.expanded = !group.expanded" @contextmenu.prevent="onGroupRightClicked(group)">
            <div class="flex py-2">
              <div class="flex w-full justify-between items-center">
                <div class="flex">
                  <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                  <h6 class="font-medium">Report: {{ group.name }}</h6>
                </div>
              </div>
              <div class="flex">
                <span class="text-xs whitespace-no-wrap mr-2">{{ groupCheckedCount(group) }} / {{ group.items.length }} checked</span>
                <feather-icon :icon="group.expanded ? 'ChevronUpIcon' : 'ChevronDownIcon'" svg-classes="h-5 w-5"/>
              </div>
            </div>
            <!--child content-->
            <div v-if="group.expanded" class="mt-2" @contextmenu.stop>
              <div v-for="(item, index) in group.items" :key="index" @click.stop class="sm:px-4 px-2 py-1 hover-bg-success-transparent-25 border border-solid border-b-0 border-l-0 border-r-0 d-theme-border-grey-light" :class="{'opacity-50': item.is_hidden && !group.is_all_items_hidden}">
                <div class="flex">
                  <vs-checkbox v-model="checked" :vs-value="item.id" color="success"/>
                  <div class="flex w-full justify-between items-center">
                    <div>
                      <h6 class="font-medium text-sm">{{ item.description }}</h6>
                      <span class="text-xs bg-ema">{{ item.code }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AclReportRepository from '@/repositories/general/acl-report-repository'
import _ from 'lodash'

export default {
  name: 'ReportsSection',
  props: ['selectedRole'],
  mounted () {
    this.getReportList()
  },
  watch: {
    selectedRole (role) {
      if (!role) return
      this.checked = _.cloneDeep(role.reportIds)
    }
  },
  computed: {
    isAnyUnsavedChanges () {
      if (!this.selectedRole) return false
      const currentRoleReportIds = this.selectedRole.reportIds
      return !_.isEqual(_.sortBy(currentRoleReportIds), _.sortBy(this.checked))
    },
    listReportsGrouped () {
      const search = this.search ? this.search.toLowerCase() : null
      if (search) {
        return this.reportsGrouped
      }
      return this.reportsGrouped
    },
    totalReports () {
      return _.flatMap(this.reportsGrouped, group => group.items).length
    }
  },
  data () {
    return {
      search: null,
      isLoading: false,
      isSaving: false,
      isExpandAll: true,
      reportsGrouped: [],
      checked: []
    }
  },
  methods: {
    getReportList () {
      this.isLoading = true
      AclReportRepository.getReports()
        .then(response => {
          this.reportsGrouped = this.buildData(response.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildData (items) {
      const groups = _.groupBy(items, item => item.group)
      return _.map(groups, (item, index) => {
        return {
          name: index,
          items: item,
          expanded: false,
          is_all_items_hidden: _.filter(item, it => !it.is_hidden).length < 1
        }
      })
    },

    save () {
      this.isSaving = true
      const params = { roleId: this.selectedRole.id, reportIds: this.checked }

      AclReportRepository.updateRoleReports(params)
        .then(response => {
          const updatedRole = response.data.role
          this.emitOnSaved(updatedRole)
          this.notifySuccess('Berhasil memperbaharui hak akses untuk role ' + this.selectedRole.name)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    onGroupRightClicked (group) {
      const reportIds = _.map(group.items, item => item.id)
      if (this.groupCheckedCount(group) === reportIds.length) {
        // remove
        this.checked = _.difference(this.checked, reportIds)
      } else {
        // push
        _.each(reportIds, item => this.checked.push(item))
        this.checked = _.uniq(this.checked)
      }
    },

    refresh () {
      this.selected = null
      this.getReportList()
    },

    toggleExpandAll (isExpand = null) {
      const expanded = isExpand !== null ? isExpand : !this.isExpandAll
      _.each(this.reportsGrouped, item => {
        item.expanded = expanded
      })
      this.isExpandAll = expanded
    },

    groupCheckedCount (group) {
      const reports = _.map(group.items, item => item.id)
      const sameItems = _.intersection(this.checked, reports)
      return sameItems.length
    },

    emitOnSaved (updatedRole) {
      this.$emit('saved', updatedRole)
    }
  }
}
</script>
