<template>
  <div>
    <vs-popup title="Edit Role" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Kode *" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.name"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full -mt-1">
            <label class="ml-1 text-xs">Deskripsi</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.description"/>
          </div>
        </div>
        <div class="vx-row float-right mt-3">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import AclRepository from '@/repositories/general/acl-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'RoleEdit',
  props: ['isActive', 'item'],
  components: { ValidationErrors },
  watch: {
    isActive (value) {
      if (value === true) this.data = _.cloneDeep(this.item)
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {}
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      AclRepository.updateRole(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
