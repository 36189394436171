import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/general/acl'

export default {
  getUsers (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/users${queryParams}`)
  },

  getRoles (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/roles${queryParams}`)
  },

  getPermissions (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/permissions${queryParams}`)
  },

  createUser (params) {
    return httpClient.post(`${endpoint}/users`, params)
  },

  updateUser (params) {
    return httpClient.patch(`${endpoint}/users`, params)
  },

  deleteUser (idUser) {
    return httpClient.delete(`${endpoint}/users/${idUser}`)
  },

  createRole (params) {
    return httpClient.post(`${endpoint}/roles`, params)
  },

  updateRole (params) {
    return httpClient.patch(`${endpoint}/roles`, params)
  },

  deleteRole (idRole) {
    return httpClient.delete(`${endpoint}/roles/${idRole}`)
  },

  updateRolePermissions (params) {
    return httpClient.post(`${endpoint}/updateRolePermissions`, params)
  }
}
