const getDefaultState = () => {
  return {
    roles: []
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_ROLES (state, roles) {
      state.roles = roles
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
