<template>
  <div class="h-full w-full">
    <div class="h-full flex flex-col border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex-none">
        <div class="flex border items-center">
          <vs-input v-model="search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari Role" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
        </div>
      </div>
      <div class="flex-none">
        <div class="flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
          <div class="flex py-2">
            <feather-icon @click="onAddClicked" icon="PlusIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
            <feather-icon @click="onEditClicked" icon="EditIcon" class="cursor-pointer px-3" svg-classes="h-4 w-4" :class="selected && selected.editable ? 'cursor-pointer hover:text-primary' : 'cursor-not-allowed'"/>
            <feather-icon @click="onDeleteClicked" icon="TrashIcon" class="cursor-pointer px-3" svg-classes="h-4 w-4" :class="selected && selected.deletable ? 'cursor-pointer hover:text-primary' : 'cursor-not-allowed'"/>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <div class="flex-none">
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="loading || listRoles.length === 0">
          <p class="text-xs text-center">{{ loading ? 'Loading...' : 'Tidak ada data.' }}</p>
        </div>
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="!loading && listRoles.length > 0">
          <p class="text-xs">Total {{ listRoles.length }} roles</p>
        </div>
      </div>
      <div class="flex-1 overflow-y-scroll">
        <div v-for="(item, index) in listRoles" :key="index" :id="index" @click="onItemSelected(item)" class="cursor-pointer select-none border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
          <div class="sm:px-4 px-2 py-2" :class="{ 'bg-primary-transparent-25': selected === item }">
            <div class="flex">
              <feather-icon v-if="selectedUser && selectedUser.roleCode === item.kode" icon="ArrowRightIcon" class="pr-3 text-dark" svg-classes="h-5 w-5"/>
              <feather-icon v-else icon="ShieldIcon" class="pr-3" svg-classes="h-5 w-5"/>
              <div class="flex w-full justify-between items-start">
                <div class="mail__details">
                  <h6 class="font-medium text-sm">{{ item.name }}</h6>
                  <span class="text-xs bg-ema">{{ item.kode }}</span>
                </div>
              </div>
              <div class="flex items-center">
                <vx-tooltip v-if="item.description" :text="item.description">
                  <feather-icon icon="InfoIcon" svg-classes="h-5 w-5"/>
                </vx-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <RoleAdd :isActive.sync="modalAdd.active" @success="getRoles"/>
    <RoleEdit :isActive.sync="modalEdit.active" :item="modalEdit.item" @success="getRoles"/>
  </div>
</template>

<script>
import AclRepository from '@/repositories/general/acl-repository'
import AclReportRepository from '@/repositories/general/acl-report-repository'
import RoleAdd from '@/views/pages/general/access-control/roles/RoleAdd'
import RoleEdit from '@/views/pages/general/access-control/roles/RoleEdit'
import _ from 'lodash'

export default {
  name: 'RolesSection',
  props: ['selectedUser', 'updatedRole'],
  components: {
    RoleEdit,
    RoleAdd
  },
  mounted () {
    this.getRoles()
  },
  watch: {
    selectedUser (val) {
      if (!val) return
      const selectedUserRole = val.roleCode
      const index = _.findIndex(this.roles, (item) => item.kode === selectedUserRole)
      this.selected = this.roles[index]
      if (index !== -1) {
        document.getElementById(index.toString()).scrollIntoView()
      }
      this.emitOnItemSelected()
    },
    updatedRole (role) {
      if (!role) return
      const index = _.findIndex(this.roles, item => item.id === role.id)
      if (index !== -1) {
        this.roles[index] = role
        this.selected = role
        this.emitOnItemSelected()
      }
    },
    roles: {
      deep: true,
      handler (value) {
        const payload = _.cloneDeep(value)
        this.$store.commit('general/accessControl/SET_ROLES', payload)
      }
    }
  },
  computed: {
    listRoles () {
      const items = this.roles
      // if search
      const search = this.search ? this.search.toLowerCase() : null
      if (search != null) {
        return _.filter(items, item => {
          return item.name.toLowerCase().includes(search) || item.kode.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  data () {
    return {
      search: null,
      loading: false,
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      roles: [],
      selected: null
    }
  },
  methods: {
    getRoles () {
      this.loading = true
      AclReportRepository.getRoles()
        .then(response => {
          this.roles = response.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onAddClicked () {
      this.modalAdd.active = true
    },

    onEditClicked () {
      if (this.selected && this.selected.editable) {
        this.modalEdit.active = true
        this.modalEdit.item = this.selected
      }
    },

    onDeleteClicked () {
      if (this.selected && this.selected.deletable) {
        this.confirmDelete(this.selected.id)
      }
    },

    onItemSelected (item) {
      this.selected = item
      this.emitOnItemSelected()
    },

    refresh () {
      this.resetSelected()
      this.getRoles()
    },

    delete (id) {
      AclRepository.deleteRole(id)
        .then(response => {
          this.resetSelected()
          this.getRoles()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus role ' + this.selected.name + '?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    resetSelected () {
      this.selected = null
      this.emitOnItemSelected()
    },

    emitOnItemSelected () {
      this.$emit('selected', this.selected)
    }
  }
}
</script>
