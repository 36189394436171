import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/general/aclReport'

export default {
  getRoles (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/roles${queryParams}`)
  },

  getReports (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/reports${queryParams}`)
  },

  updateRoleReports (params) {
    return httpClient.post(`${endpoint}/updateRoleReports`, params)
  }
}
